import React from 'react';
import Grid from '@material-ui/core/Grid';

function MyTotal(props) {
  return (
    <Grid container style={props.style}>
      <Grid item xs={12}>
        本人誦經累計次數： { props.myTotal }
      </Grid>
      <Grid item xs={12}>
        全體誦經累計次數： { props.globalTotal }
      </Grid>
    </Grid>
  )
}

export default MyTotal;