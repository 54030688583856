import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

function Verify(props) {
  const styles = {
    title: {
      marginBottom: 20,
      fontSize: '0.8em'
    },
    note: {
      fontSize: '0.4em',
      backgroundColor: '#dddddd',
      border: '1px solid #666666',
      marginTop: 10
    }
  }
  return (
    <React.Fragment>
      <div style={styles.title}>
        請確認您的資料
      </div>
      <form noValidate autoComplete="off" onSubmit={props.handleVerifySubmit}>
        <TextField
          fullWidth={true}
          id="phone"
          autoFocus={true}
          label="電話號碼"
          variant="filled"
          size="medium"
          color="primary"
          disabled={true}
          style={{backgroundColor: '#ffffff', textAlign: 'center'}}
          defaultValue={props.phone}
        />
        <TextField
          fullWidth={true}
          id="name"
          autoFocus={true}
          label="名字"
          variant="filled"
          size="medium"
          color="primary"
          onChange={props.handleNameChange.bind(this, 'name')}
          style={{backgroundColor: '#ffffff', textAlign: 'center'}}
          value={props.name}
        />
        <TextField
          fullWidth={true}
          id="email"
          autoFocus={true}
          label="電子郵件"
          variant="filled"
          size="medium"
          color="primary"
          onChange={props.handleEmailChange.bind(this, 'email')}
          style={{backgroundColor: '#ffffff', textAlign: 'center'}}
          value={props.email}
        />

        <div>
          <Card variant="outlined" style={styles.note}>
            <CardContent>
              如果您提供 Gmail 電子郵件，您將可以用 Google 來登入。<br />快速又簡單！
            </CardContent>
          </Card>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{marginTop: 30}}
          onClick={props.handleVerifySubmit}
          disabled={!props.name.length || !props.email.match(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)}
        >
          登入
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          style={{marginTop: 30}}
          onClick={props.backToHome}
        >
          返回
        </Button>
      </form>
    </React.Fragment>
  )
}

export default Verify;