import React, { Component } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import ReactGA from 'react-ga';


import MyTotal from './MyTotal';

import { types, gaEvents } from '../utils/constants';
import { isNum, GaEvent } from '../utils/utils';
import { config } from '../config/config';

const { application, google } = config;

const dailyTarget = 3;

class MySubject extends Component {
  constructor(props) {
    super(props);

    this.updateState = this.updateState.bind(this);
    this.handleRead = this.handleRead.bind(this);
    this.turnOnOff = this.turnOnOff.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEnterMore = this.handleEnterMore.bind(this);
    this.handleUpdateEnterMore = this.handleUpdateEnterMore.bind(this);
    this.handleSubmitMore = this.handleSubmitMore.bind(this);
    this.getDataByToken = this.getDataByToken.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.redirectToHome = this.redirectToHome.bind(this);

    this.state = {
      id: this.props.id,
      data: {},
      api: application.url.prod,
      error: '',
      updated: {},
      showFinal: false,
      global: {},
      count: {},
      enterMore: false,
      moreText: {},
      gaId: google.GAId.prod
    }
  }

  componentDidMount() {
    let { id } = this.props;

    // if id is not available, get it from url
    if (!id) {
      if (this.props.match.params.id) {
        id = this.props.match.params.id;
        this.setState({
          id
        })
      }
    }
    const token = window.localStorage.getItem('token');
    let { api, gaId } = this.state;
    
    if (-~(window.location.hostname.indexOf('localhost'))) {
      api = application.url.local;
      gaId = google.GAId.local;
    }

    this.setState({
      api,
      gaId
    });

    // console.log('----token:', token);
    // console.log('----id:', id);
    // call API
    if (token) {
      this.getDataByToken(api, token);
    } else {
      GaEvent({action: gaEvents.redirectToHome});
      if (this.props.history) {
        this.props.history.push('/');
      } else {
        window.location = '/';
      }
    }

    ReactGA.initialize(gaId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  getDataByToken(api, token) {
    // console.log('----getDataByToken');
    axios({
      method: 'get',
      url: `${api}/api/v1/records`,
      headers: { token }
    })
      .then(response => {
        // console.log('------response.data;;', response.data);
        this.updateState(response.data);
      })
      .catch(err => {
        console.log('---err:', err.response);
        GaEvent({action: gaEvents.redirectToHome});
        let errorCode = '';
        if (err.response.data && err.response.data.error === "login_again") {
          errorCode = '?error=3';
        }
        if (this.props.history) {
          this.props.history.push('/'+errorCode);
        } else {
          window.location = '/'+errorCode;
        }
      });
  }

  redirectToHome() {
    GaEvent({action: gaEvents.redirectToHome});
    if (this.props.history) {
      this.props.history.push('/?error=1');
    } else {
      window.location = '/?error=1';
    }
  }

  getTotal(type) {
    let { global, count } = this.state;
    let num = global;
    switch(type) {
      case 'global':
        num = global;
        break;
      case 'mine':
        num = count;
        break;
      default:
        num = global
    }

    if (num && Object.keys(num).length > 0) {
      return Object.values(num).reduce((acc, val) => acc + val);
    }
    return 0;
  }

  updateState(response) {
    this.setState({
      data: response.data,
      global: response.data.global,
      count: response.data.count,
      id: response.data.cellId
    })
  }

  turnOnOff(type, on) {
    this.setState({
      updated: {
        [type]: on
      }
    });
  }

  handleRead(code, num) {
    const { id, api } = this.state;
    let todayData;
    axios.patch(`${api}/api/v1/records/${id}`, {
      type: code,
      time: (new Date()).getTime(),
      num: num || 0
    })
      .then(response => {
        this.updateState(response.data);
        this.turnOnOff(code, true);
        todayData = response.data.data.today;
      })
      .catch(err => {
        this.setState({
          error: err.response.data.message || ''
        })
      })
      .finally(_ => {
        setTimeout(_ => {
          this.turnOnOff(code, false);
        }, 3000);

        // show HuiXiang if any of the current value is 3
        if (this.showFinal(code, todayData)) {
          this.setState({
            showFinal: true
          })
        }
      });
    GaEvent({action: gaEvents.recordScriptureChant});
  }

  isCompleted(type) {
    return this.state.data.today && this.state.data.today[type.code] >= 3;
  }

  showFinal(thisType, today) {
    let allMore = true;
    let three = false;
    if (today) {
      for (let theType of types) {
        const type = theType.code;
        if (thisType === type && today[type] === 3) {
          three = true;
          continue;
        }
        if (!today[type]) {
          allMore = false;
        }
        if (today[type] < dailyTarget) {
          allMore = false;
        }
      }
    }
    return allMore && three;
  }

  handleCloseModal() {
    this.setState({
      showFinal: false
    });
  }

  handleOpenModal() {
    this.setState({
      showFinal: true
    });
    GaEvent({action: gaEvents.showHuiXiangWen});
  }

  handleEnterMore(show) {
    this.setState({
      enterMore: show
    })
  }

  handleUpdateEnterMore(event) {
    let { moreText } = this.state;
    let value = event.target.value;

    if (!isNum(value)) {
      return;
    }

    if (value <= 0) {
      return;
    }

    moreText[event.target.id] = value;

    this.setState({
      moreText: moreText
    })
  }

  handleSubmitMore(type) {
    try {
      this.handleRead(type, parseInt(this.state.moreText[type]) || 0);
    } catch (e) {
      console.error('--- error parsing Int:', e.message);
    }

    this.handleEnterMore(false);
    let moreText = this.state.moreText;
    if (moreText[type]) {
      delete moreText[type];
    }
    this.setState({
      moreText
    });
    GaEvent({action: gaEvents.recordScriptureBulk});
  }

  handleLogout() {
    window.localStorage.removeItem('token');
    GaEvent({action: gaEvents.logout});
    if (this.props.history) {
      this.props.history.push('/');
    } else {
      window.location = '/';
    }
  }

  render() {
    let {
      error,
      updated,
      showFinal,
      data,
      id
    } = this.state;

    const token = window.localStorage.getItem('token');

    const styles = {
      root: {
        flexGrow: 1
      },
      textBackground: {
        backgroundColor: 'rgba(68, 68, 68, 0.4)',
        borderRadius: 10,
        alpha: 0.8,
        padding: 30,
        position: 'relative'
      },
      layer: {
        height: '100vh',
        width: '100vw'
      },
      title: {
        textAlign: 'center',
        marginBottom: 50
      },
      header: {
        height: 30
      },
      text: {
        paddingTop: 15
      },
      itemText: {
        textAlign: 'right',
        paddingRight: 30
      },
      itemValue: {
        paddingLeft: 30
      },
      bottmeText: {
        marginTop: 30
      },
      center: {
        textAlign: 'center'
      },
      total: {
        fontSize: '1.5em',
        marginTop: 30
      },
      bottom: {
        minHeight: 400
      },
      globalTotal: {
        fontSize: '1.2em',
        marginTop: 30,
        textAlign: 'center',
        padding: 30,
        color: '#000000',
        backgroundColor: 'rgb(255, 152, 0)'
      },
      addMore: {
        padding: 'auto 15px'
      },
      logout: {
        position: 'absolute',
        top: 20,
        right: 20
      },
      logoutButoon: {
        color: '#ffff00',
        cursor: 'pointer'
      },
      logoutId: {
        fontSize: '0.8em',
        textAlign: 'right'
      }
    };

    return (
      <Grid container style={styles.root} spacing={2}>
        <Grid item md={1} xs={false}>
          
        </Grid>
        <Grid item md={10} xs={12}>
          <Grid container style={styles.textBackground}>
            <Grid item xs={12}>
              <Grid container justify="center" >
                <div style={styles.header} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <h1 style={styles.title}>新冠疫情<br />誦經迴向</h1>
                {
                  error && (
                    <div style={{textAlign: 'center', color: '#ff0000', backgroundColor: '#cccccc', border: '1px solid #222222', borderRadius: 5, padding: 10}}>Error: {error}</div>
                  )
                }
                <Grid container style={styles.bottmeText}>
                  <Grid item xs={12} style={{...styles.text, ...styles.center}}>
                    誦完經後，再點擊
                  </Grid>
                </Grid>
                <Grid container alignItems="center" style={styles.bottmeText}>
                  {
                    types.map((type, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Grid item xs={6} style={styles.itemText}>
                            {type.name}
                          </Grid>
                          <Grid item xs={6} style={styles.itemValue}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleRead.bind(this, type.code, 1)}
                            >
                              {
                                updated[type.code] && (
                                  <CheckCircleOutlineIcon />
                                )
                              }
                              {
                                !updated[type.code] && (
                                  <React.Fragment>{(data.today && data.today[type.code]) || 0}</React.Fragment>
                                )
                              }
                            </Button>
                            {
                              this.isCompleted(type) && (
                                <span style={{ marginLeft: 30, color: '#00ff00' }}><DoneAllIcon /></span>
                              )
                            }
                          </Grid>
                        </React.Fragment>
                      )
                    })
                  }
                  <Grid item xs={12} style={{...styles.center, ...styles.bottmeText}}>
                    <Button
                      variant="outlined" size="small"
                      onClick={this.handleEnterMore.bind(this, !this.state.enterMore)}
                    >直接輸入多次誦經紀錄</Button>
                  </Grid>

                  {
                    this.state.enterMore && (
                      <React.Fragment>
                        {
                          types.map((type, i) => {
                            return (
                              <Grid container key={i}>
                                <Grid item xs={12} style={styles.text}>
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    bgcolor="transparent"
                                    style={styles.center}
                                  >
                                    <Box>
                                      <TextField
                                        id={type.code}
                                        label={type.name}
                                        variant="outlined"
                                        onChange={this.handleUpdateEnterMore}
                                        value={this.state.moreText[type.code] || ''}
                                      />
                                    </Box>
                                    {
                                      this.state.moreText[type.code] && (
                                        <Box ml={2}>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            id={type.code}
                                            onClick={this.handleSubmitMore.bind(this, type.code)}
                                          >
                                            提交
                                          </Button>
                                        </Box>
                                      )
                                    }
                                  </Box>
                                </Grid>
                              </Grid>
                            )
                          })
                        }
                      </React.Fragment>
                    )
                  }
                </Grid>
                <Grid container style={styles.bottmeText}>
                  <Grid item xs={12} style={{...styles.text, ...styles.center}}>
                    以上經典各誦完三遍後，再唸迴向文
                  </Grid>
                </Grid>
                <Grid container style={styles.center}>
                  <Grid item xs={12} style={{...styles.center, ...styles.text}}>
                    <Button
                      variant="contained"
                      onClick={this.handleOpenModal.bind(this)}
                    >
                      迴向文
                    </Button>
                  </Grid>
                </Grid>

                <MyTotal myTotal={this.getTotal('mine')} globalTotal={this.getTotal('global')} style={styles.globalTotal} />

              </Grid>
            </Grid>

            {
              token && (
                <div style={styles.logout}>
                  <Button
                    variant="outlined"
                    style={styles.logoutButoon}
                    onClick={this.handleLogout.bind(this)}
                  >登出</Button>
                  <br />
                  <span style={styles.logoutId}>{id}</span>
                </div>
              )
            }
          </Grid>
          <Grid item xs={12} style={{...styles.bottom}}>

          </Grid>
        </Grid>
        
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={showFinal}
          scroll={'body'}
          onClose={this.handleCloseModal}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle style={{textAlign: 'center'}} id="max-width-dialog-title">迴向文</DialogTitle>
          <DialogContent  dividers={false} style={{textAlign: 'center'}}>
            <DialogContentText>
              餘藴(乾道名字)<br />
              信士(坤道名字)<br />
              <br />
              願將以上誦經功德<br />
              恭轉呈<br />
              彌勒袓師慈悲作主<br />
              迴向給新冠肺炎疫情<br />
              懇叩請<br />
              皇母慈憫<br />
              諸天仙佛菩薩慈悲撥轉<br />
              將此疫情降到最低不再擴散<br />
              保佑衆生萬物生命安全<br />
              是所至禱<br />
              聖慈昭格釆納<br />
              無任懇禱 沾恩之至
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal} color="primary">
              關閉
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}

export default MySubject;