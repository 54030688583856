import ReactGA from 'react-ga';

const isNum = id => /^[0-9]*$/.test(id);

const GaEvent = data => {
  let ga = {};
  if (data) {
    if (data.action) {
      ga.category = 'click';
      ga.action = data.action;
    }
    if (data.category) {
      ga.category = data.category;
    }
    if (data.label) {
      let label = data.label;
      if (label.indexOf('@') > 0 && label.indexOf('.') > 0) {
        label = label.substring(0, label.lastIndexOf('.'));
        label = label.replace('@', '_');
      }
      ga.label = label;
    }
    if (data.value) {
      ga.value = data.value;
    }
  }


  ReactGA.event(ga);
};

export {
  isNum,
  GaEvent
};