const types = [
  { name: '彌勒救苦真經', code: 'mile', val: 3 },
  { name: '心經', code: 'xinjing', val: 1 },
  // { name: '忠義鼎', code: 'zhongyiding', val: 1 },
  // { name: '忠義精神', code: 'zhongyijingshen', val: 1 },
  // { name: '清靜經', code: 'qingjingjing', val: 1 },
  // { name: '修辦道程圖', code: 'xiuban', val: 2 },
  // { name: '萬世金鐘', code: 'wanshi', val: 2 },
  // { name: '桃園明聖經', code: 'taoyuan', val: 5 },
  // { name: '濟公活佛救世真經', code: 'jigong', val: 2 },
  // { name: '神課', code: 'shengke', val: 6 },
  // { name: '善歌', code: 'shange', val: 3 }
];

const errorMessages = {
  USER_NOT_FOUND: '對不起，我們沒有您的資料！',
  LOGIN_WITH_GOOGLE: '您已經註冊了，請用谷歌來登入',
  LOGIN_FAILED: '對不起，登入失敗！',
  PHONE_ALREADY_IN_USE: '電話已經被用了！',
  LOGIN_AGAIN: '請再登入一次！',
  ACCOUNT_ALREADY_IN_USE: '帳號已經被用了！'
};

const gaEvents = {
  loginByPhone: 'login_by_phone',
  loginByPhoneFailed: 'login_by_phone_failed',
  loginByGoogle: 'login_by_google',
  loginByGoogleFailed: 'login_by_google_failed',
  loginByUsername: 'login_by_username',
  loginByUsernameFailed: 'login_by_username_failed',
  newUserRegister: 'new_user_register',
  redirectToHome: 'redirect_to_home',
  recordScriptureChant: 'record_scripture_chant',
  recordScriptureBulk: 'record_scripture_bulk',
  logout: 'logout',
  showHuiXiangWen: 'show_HuiXiangWen'
};

export {
  types,
  errorMessages,
  gaEvents
};