import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

function UsernameLogin(props) {
  return (
    <form noValidate autoComplete="off" onSubmit={props.handleUsernameSubmit}>
      <TextField
        fullWidth={true}
        id="username"
        autoFocus={true}
        label="登入名稱(您的電子郵件)"
        variant="filled"
        size="medium"
        color="primary"
        onChange={props.handleUsernameChange}
        style={{backgroundColor: '#ffffff', textAlign: 'center'}}
        value={props.username}
      />
      <TextField
        fullWidth={true}
        id="password"
        autoFocus={true}
        label="密碼(您的電話號碼)"
        variant="filled"
        size="medium"
        color="primary"
        type="password"
        onChange={props.handlePasswordChange}
        style={{backgroundColor: '#ffffff', textAlign: 'center'}}
        value={props.password}
      />
      <Button
        variant="contained"
        color="primary"
        style={{marginTop: 30}}
        onClick={props.handleUsernameSubmit}
        type="submit"
        disabled={!props.username.length || !props.password.length}
      >
        登入
      </Button>
    </form>
  )
}

export default UsernameLogin;