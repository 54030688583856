const config = {
  google: {
    clientID: '609161053666-poifbmhqqkvqoh73d2e0sndikh5sqa9f.apps.googleusercontent.com',
    GAId: {
      local: 'UA-164587068-1',
      prod: 'UA-164587068-2'
    }
  },
  application: {
    url: {
      local: '//localhost:8001',
      prod: 'https://chonghuiapi.fycd.us'
    }
  }
};

export {
  config
};