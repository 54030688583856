import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

function NotFound(props) {
  const styles = {
    root: {
      flexGrow: 1
    },
    textBackground: {
      backgroundColor: 'rgba(68, 68, 68, 0.4)',
      borderRadius: 10,
      alpha: 0.8,
      padding: 30
    },
    layer: {
      height: '100vh',
      width: '100vw'
    },
    title: {
      textAlign: 'center'
    },
    header: {
      height: 30
    },
    text: {
      paddingTop: 15
    },
    center: {
      textAlign: 'center'
    }
  };

  return (
    <Grid container style={styles.root} spacing={2}>
      <Grid item xs={1}>
        <div style={styles.layer} />
      </Grid>
      <Grid item xs={10}>
        <Grid item xs={12} style={styles.textBackground}>
          <Grid item xs={12}>
            <Grid container justify="center" >
              <div style={styles.header} />
            </Grid>
          </Grid>
          <div style={{...styles.text, ...styles.center}}>
            <h1 style={styles.title}>對不起，無法找到您的資料。</h1>
            <Link to="/">
              <Button
                variant="contained"
                style={{marginTop: 30}}
              >
                返回
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotFound;