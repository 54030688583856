import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function PhoneDialog(props) {
  return (
    <div>
      <Dialog
        open={props.openDialog}
        onClose={props.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogContent>
          <DialogContentText>
            請提供您的電話號碼
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="電話號碼"
            type="text"
            fullWidth
            onChange={props.handlePhoneChange}
            value={props.phone}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseDialog} color="primary">
            取消
          </Button>
          <Button 
            onClick={props.handleAddPhoneToGoogle} 
            color="primary"
            disabled={props.phone.length < 10}
          >
            提交
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PhoneDialog;