import React, { Component } from 'react';

import MySubject from './MySubject';
import NotFound from './NotFound';
import { isNum } from '../utils/utils';

class ThePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMe: false
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({
      showMe: isNum(id) && (id > 0),
      id
    });
  }

  render() {
    let { showMe } = this.state;
    return (
      <React.Fragment>
        {
          showMe && <MySubject id={this.state.id} />
        }

        {
          !showMe && <NotFound />
        }
      </React.Fragment>
    )
  }
}

export default ThePage;