import React, { PureComponent } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import ReactGA from 'react-ga';

import GlobalTotal from './GlobalTotal';
import LoginSelection from './LoginSelection';
import Verify from './Verify';
import PhoneDialog from './PhoneDialog';

import { config } from '../config/config';
import {
  errorMessages,
  gaEvents
} from '../utils/constants';

import { GaEvent } from '../utils/utils';

const { application, google } = config;

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.handlePhoneSubmit = this.handlePhoneSubmit.bind(this);
    this.getGlobalTotal = this.getGlobalTotal.bind(this);
    this.handleGoogleResponse = this.handleGoogleResponse.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleUsernameSubmit = this.handleUsernameSubmit.bind(this);
    this.handleVerifySubmit = this.handleVerifySubmit.bind(this);
    this.backToHome = this.backToHome.bind(this);
    this.doGoogleLogin = this.doGoogleLogin.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleAddPhoneToGoogle = this.handleAddPhoneToGoogle.bind(this);

    this.state = {
      phone: '',
      api: application.url.prod,  //'https://chonghuiapi.fycd.us',
      global: {},
      showError: false,
      errorMessage: '',
      username: '',
      password: '',
      loginType: 1,
      showVerify: false,
      currentUser: null,
      name: '',
      email: '',
      id_token: null,
      openDialog: false,
      gaId: google.GAId.prod
    }
  }

  componentDidMount() {
    let { api, gaId } = this.state;
    if (-~(window.location.hostname.indexOf('localhost'))) {
      api = application.url.local; //'//localhost:7001'
      gaId = google.GAId.local;
    }

    if (window.location.search) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const error = urlParams.get('error');
      if (error === '1') {
        this.setState({
          showError: true,
          errorMessage: errorMessages.USER_NOT_FOUND
        });
      } else if (error === '3') {
        this.setState({
          showError: true,
          errorMessage: errorMessages.LOGIN_AGAIN
        });
      }
    }

    this.setState({
      api,
      gaId
    });

    // call API
    axios({
      method: 'get',
      url: `${api}/api/v1/global`,
      headers: { token: (window.localStorage.getItem('token') || '')}
    })
      .then(response => {
        this.setState({
          global: response.data.data
        });
      });

    ReactGA.initialize(gaId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleFieldChange(field, event) {
    this.setState({
      [field]: event.target.value
    })
  }

  handlePhoneSubmit(event) {
    event.preventDefault();
    // console.log('-----handlePhoneSubmit');
    
    const { api } = this.state;
    axios.get(`${api}/api/v1/verify/?id=${this.state.phone}`)
      .then(response => {
        let user = response.data;

        if (user) {
          if (user.registered) {
            let message = errorMessages.LOGIN_WITH_GOOGLE;
            this.setState({
              showError: true,
              errorMessage: message,
              loginType: 2
            });
          } else {
            this.setState({
              showVerify: true,
              name: user.name || '',
              email: user.email || ''
            });
          }
        } else {
          let message = errorMessages.USER_NOT_FOUND;
          this.setState({
            showError: true,
            errorMessage: message
          });
        }
      })
      .catch(err => {
        if (err.response) {
          console.log('-----err.response.data:', err.response.data);
          let message = err.response.data.message;
          if (err.response.data.error === 'user_notfound_error') {
            message = errorMessages.USER_NOT_FOUND;
          }
          this.setState({
            showError: true,
            errorMessage: message
          });
        }
      });
  }

  getGlobalTotal() {
    let { global } = this.state;
    if (global && Object.keys(global).length > 0) {
      return Object.values(global).reduce((acc, val) => acc + val);
    }
    return 0;
  }

  handleGoogleResponse(response) {
    let { api } = this.state;
    const email = response.getBasicProfile().yu;
    const id_token = response.getAuthResponse().id_token;

    this.setState({
      id_token,
      email
    });

    if (email) {
      axios.get(`${api}/api/v1/verify?email=${email}`)
        .then(response => {
          let found = response.data.found;
          if (found) {
            let phone = response.data.cellId;
            this.setState({
              phone
            });
            this.doGoogleLogin();
          } else {
            this.setState({
              openDialog: true
            });
          }
        })
    }
  }

  handleAddPhoneToGoogle() {
    const { phone, api } = this.state;
    this.setState({
      openDialog: false
    });

    // console.log('-----phone', phone);
    if (phone) {
      axios.get(`${api}/api/v1/verify?cellId=${phone}`)
        .then(response => {
          let available = response.data.available;
          // console.log('-----available', available);
          if (available) {
            this.doGoogleLogin();
          } else {
            this.setState({
              openDialog: true,
              showError: true,
              errorMessage: errorMessages.PHONE_ALREADY_IN_USE
            });
          }
        })
    }
  }

  doGoogleLogin() {
    const { id_token, phone, api } = this.state;

    axios.post(`${api}/api/v1/google/login`, {
      accessToken: id_token,
      cellId: phone
    })
      .then(response => {
        if (response.data) {
          const { token, success, cellId } = response.data;
          if (success === true && token && cellId) {
            window.localStorage.setItem('token', token);
            console.log('----redirecting to /mypage/'+cellId);
            GaEvent({action: gaEvents.loginByGoogle, label: cellId+''});
            setTimeout(_ => {
              this.props.history.push('/mypage/'+cellId);
            }, 500);
          }
        }
      })
      .catch(err => {
        let errorMessage = errorMessages.LOGIN_FAILED;
        if (err.response && err.response.data && err.response.data.error === 'user_already_registered') {
          errorMessage = errorMessages.PHONE_ALREADY_IN_USE;
        }
        this.setState({
          showError: true,
          errorMessage: errorMessage
        });
        GaEvent({action: gaEvents.loginByGoogleFailed, label: errorMessage});
      });
  }

  handleUsernameSubmit(event) {
    // console.log('-----handleUsernameSubmit')
    event.preventDefault();
    const { username, password, api } = this.state;
    axios.post(`${api}/api/v1/user/login`, {
      username,
      password
    })
      .then(response => {
        if (response.data) {
          const { token, success, cellId } = response.data;
          if (success === true && token && cellId) {
            window.localStorage.setItem('token', token);
            GaEvent({action: gaEvents.loginByUsername, label: username});
            return this.props.history.push('/mypage/'+cellId);
          }
        }
      })
      .catch(err => {
        this.setState({
          showError: true,
          errorMessage: errorMessages.LOGIN_FAILED
        });
        GaEvent({action: gaEvents.loginByUsernameFailed, label: errorMessages.LOGIN_FAILED});
      });
  }

  toggleDrawer(event) {
    this.setState({
      showError: false,
      errorMessage: ''
    })
  }

  handleVerifySubmit() {
    const { name, email, phone, api } = this.state;
    // console.log('----', name, email, phone);
    axios.post(`${api}/api/v1/register`, {
      email,
      name,
      id: phone
    })
      .then(response => {
        if (response.data) {
          const { token, success, cellId } = response.data;
          if (success === true && token && cellId) {
            window.localStorage.setItem('token', token);
            return this.props.history.push('/mypage/'+cellId);
          }
        }
      });

    GaEvent({action: gaEvents.newUserRegister});
  }

  backToHome() {
    this.setState({
      showVerify: false,
      loginType: 1
    })
  }

  handleCloseDialog() {
    this.setState({
      openDialog: false,
      phone: ''
    })
  }

  render() {
    let {
      showVerify,
      phone,
      name,
      email,
      openDialog
    } = this.state;
    const styles = {
      root: {
        flexGrow: 1,
      },
      paper: {
        height: 140,
        width: 100,
      },
      header: {
        height: 30
      },
      control: {
        padding: 'auto'
      },
      text: {
        paddingTop: 15
      },
      textBackground: {
        backgroundColor: 'rgba(68, 68, 68, 0.4)',
        borderRadius: 10,
        alpha: 0.8,
        padding: 30
      },
      layer: {
        height: '100vh',
        width: '100vw'
      },
      phone: {
        fontSize: '2.0em',
        width: '50%',
        marginTop: 30,
        textAlign: 'center',
        display: 'block'
      },
      googleLogin: {
        marginTop: 30,
        textAlign: 'center'
      },
      globalTotal: {
        fontSize: '1.2em',
        marginTop: 30,
        textAlign: 'center',
        display: 'block',
        padding: 30,
        color: '#000000',
        backgroundColor: 'rgb(255, 152, 0)'
      },
      center: {
        textAlign: 'center'
      },
      errorMessage: {
        padding: 50,
        textAlign: 'center',
        fontWeight: 'bold'
      },
      extraPadding: {
        height: 50
      }
    };

    return (
      <Grid container style={styles.root}>
        <Grid item xs={1}>
          <div style={styles.layer} />
        </Grid>
        <Grid item xs={10}>
          <Grid item xs={12}>
            <Grid container justify="center" >
              <div style={styles.header}/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2} style={styles.textBackground}>
              <Grid item xs={12}>
                <div style={{textAlign: 'center'}}>
                  <h1>新冠疫情<br />誦經迴向</h1>
                </div>

                <Grid item xs>
                  <Typography>人人祝愿 世界平安</Typography>
                </Grid>
                <Typography>
                  『新冠疫情』嚴峻，衝擊眾人生命安危，需要大家團結互助，共渡難關。舊金山道務中心發起「每日居家修行功課」，響應「每日至少茹素一餐，誦經叩首，手機功德迴向」，感動上天，叩求上天慈悲撥轉，挽化疫情，止息傷故。
                </Typography>
                <Typography>
                  虔誦經典 挽化災劫
                </Typography>
                <Typography>
                  誦經叩禱活動至疫情緩解為止。
                </Typography>
                <Typography>
                  讀誦前 請茹素
                </Typography>
              </Grid>
              <GlobalTotal total={this.getGlobalTotal()} style={styles.globalTotal} />

              <Grid item xs={false} md={3} />
              <Grid item xs={12} md={6} style={styles.phone}>
                {
                  showVerify &&
                  <Verify
                    phone={phone}
                    name={name}
                    email={email}
                    handleVerifySubmit={this.handleVerifySubmit}
                    handleNameChange={this.handleFieldChange}
                    handleEmailChange={this.handleFieldChange}
                    backToHome={this.backToHome}
                  />
                }
                {
                  !showVerify &&
                  <LoginSelection
                    handlePhoneSubmit={this.handlePhoneSubmit}
                    handlePhoneChange={this.handleFieldChange}
                    handleGoogleResponse={this.handleGoogleResponse}
                    handleUsernameChange={this.handleFieldChange}
                    handlePasswordChange={this.handleFieldChange}
                    handleUsernameSubmit={this.handleUsernameSubmit}
                    username={this.state.username}
                    password={this.state.password}
                    loginType={this.state.loginType}
                  />
                }

              </Grid>
              <Grid item xs={false} md={3} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" >
              <div style={styles.extraPadding}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}/>

        <Drawer anchor={'bottom'} open={this.state.showError} onClose={this.toggleDrawer.bind(this)}>
          <div style={styles.errorMessage}>
            { this.state.errorMessage }
          </div>
        </Drawer>

        <PhoneDialog
          openDialog={openDialog}
          handlePhoneChange={this.handleFieldChange.bind(this, 'phone')}
          handleCloseDialog={this.handleCloseDialog}
          handleAddPhoneToGoogle={this.handleAddPhoneToGoogle}
          phone={phone}
        />
      </Grid>
    )
  }
}

export default Home;