import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

function PhoneLogin(props) {
  return (
    <form noValidate autoComplete="off" onSubmit={props.handlePhoneSubmit}>
      <TextField
        fullWidth={true}
        id="phone"
        autoFocus={true}
        label="電話號碼"
        variant="filled"
        size="medium"
        color="primary"
        onChange={props.handlePhoneChange}
        style={{backgroundColor: '#ffffff', textAlign: 'center'}}
      />
      <Button
        variant="contained"
        color="primary"
        style={{marginTop: 30}}
        onClick={props.handlePhoneSubmit}
      >
        登入
      </Button>
    </form>
  )
}

export default PhoneLogin;