import React from 'react';
import Container from '@material-ui/core/Container';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './Home';
import ThePage from './ThePage';
import MySubject from './MySubject';

function Index() {
  const styles = {
    container: {
      backgroundImage: `url(${"/bbg_o.png"})`,
      backgroundPosition: '50% 25%', /* Center the image */
      backgroundRepeat: 'no-repeat', /* Do not repeat the image */
      backgroundSize: 'cover'
    },
    main: {
      color: '#ffffff',
      width: '100vw'
    }
  };

  return (
    <Router>
      <div style={{position: 'absolute', width: '100vw', zIndex: -100, height: '100vh', ...styles.container}} />
      <div style={{...styles.main, ...styles.container}}>
        <Container fixed>
          <Switch>
            <Route path="/mypage/:id/" component={MySubject} />
            <Route path="/mypage/" component={MySubject} />
            <Route path="/:id/" component={ThePage} />
            <Route exact path="/" component={Home} />
          </Switch>
        </Container>
      </div>
    </Router>
  )
}

export default Index;