import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

import PhoneLogin from './PhoneLogin';
// import UseGoogleLogin from './UseGoogleLogin';
import UsernameLogin from './UsernameLogin';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(204, 204, 204, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
}))(MuiExpansionPanelDetails);



function LoginSelection(props) {
  const [expanded, setExpanded] = React.useState(1);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    setExpanded(props.loginType);
  }, [props.loginType]);

  return (
    <div>
      <ExpansionPanel square expanded={expanded === 1} onChange={handleChange(1)}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>以名稱及密碼來登入</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{textAlign: 'center', width: '100%'}}>
            <UsernameLogin
              handleUsernameChange={props.handleUsernameChange.bind(this, 'username')}
              handlePasswordChange={props.handlePasswordChange.bind(this, 'password')}
              handleUsernameSubmit={props.handleUsernameSubmit.bind(this)}
              username={props.username}
              password={props.password}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 3} onChange={handleChange(3)}>
        <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>以電話號碼來登入</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <PhoneLogin
            handlePhoneSubmit={props.handlePhoneSubmit}
            handlePhoneChange={props.handlePhoneChange.bind(this, 'phone')}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default LoginSelection;


// <ExpansionPanel square expanded={expanded === 2} onChange={handleChange(2)}>
//   <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
//     <Typography>以谷歌來登入</Typography>
//   </ExpansionPanelSummary>
//   <ExpansionPanelDetails>
//     <div style={{textAlign: 'center', width: '100%'}}>
//       <UseGoogleLogin
//         handleGoogleResponse={props.handleGoogleResponse}
//       />
//     </div>
//   </ExpansionPanelDetails>
// </ExpansionPanel>



